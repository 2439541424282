import { HeadController } from '@components';
import { NextPageWithConfig } from '@modules/_app';
import { AuthPage } from '@modules/auth/auth-page';

const SignIn: NextPageWithConfig = () => {
  return (
    <>
      <HeadController title="Sign In" />
      <AuthPage page="sign-in" />
    </>
  );
};

SignIn.config = {
  guest: true,
  offerModalHidden: true
};

export default SignIn;
